














































import vuex from '../store';
import API, { Types } from '@/modules/API';
import { cloneDeep } from 'lodash';
import PropEditor from '@/components/PropEditor.vue';
import { getProjectProperty, setProjectProperty, setCompanyProperty, getUserProfile, deleteUser } from '@/modules/ApiUsers';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import UserDialog from '@/pages/userManagement/UserDialog.vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { User, Props, Users, Projects, Global} from '@/store';
import UsersList from '@/pages/userManagement/UsersList.vue';

@Component({
    components: {
      PropEditor,
      ConfirmDialog,
      UserDialog,
      UsersList
    }
})
export default class UsersManagement extends Vue{
    @User.State('project') project;
    @Props.State('company_properties') company_properties;
    @Users.State('users_by_email') users_by_email;
    @Projects.State('list') projectsList;
    @Projects.State('projects_count') projects_count;
    @Global.State('lang') lang;

    deleteDialogOpen = false;
    userDialogOpen = false;
    user = {};
    dialog_type = '';
    notifications = null;
    loading = true;
    mobile_roles = {};
    users_list = [];

    async mounted() {
      vuex.commit('Global/setPageTitle', 'User Management');
      await this.generatePageData();
      this.loading = false;
    }
    
    get project_name_by_id(){
      const name_by_id = {};
      this.projectsList.forEach((project) => name_by_id[project.id] = project.name);
      return name_by_id;
    }

    get project_id_by_name(){
      const id_by_name = {};
      this.projectsList.forEach((project) => id_by_name[project.name] = project.id);
      return id_by_name;
    }

    @Watch('projectsList')
    async updateData(){
      this.loading = true;
      if (this.projectsList.length === this.projects_count) {
        await this.generatePageData();
        this.loading = false;
      }
    }

    @Watch('users_by_email')
    setUsersList(){
      this.users_list = !this.users_by_email
        ? []
        : cloneDeep(Object.values(this.users_by_email).map((user_data) => user_data));
    }

    async generatePageData() {
      this.loading = true;
      if (this.projectsList.length !== this.projects_count) return;

      await vuex.dispatch('Users/fetchAllUsers');
      await this.fetchNotifications();
      await this.fetchMobileRoles();
      this.setUsersList();

      this.loading = false;
    }

    async fetchMobileRoles(){
      await Promise.all(this.projectsList.map(async (project) => {
        const mobile_roles = await getProjectProperty(project.company, project.id, 'mobile.user_roles');
        this.mobile_roles[project.id] = mobile_roles && mobile_roles.users || [];
      }));
    }

    setUserDialog(type, user, is_open) {
      this.dialog_type = type;
      this.user = user;
      this.userDialogOpen = is_open;
    }

    openDeleteUserDialog(user){
      this.user = user;
      this.deleteDialogOpen = true;
    }

    closeUserDialog() {
      this.dialog_type = '';
      this.user = {};
      this.deleteDialogOpen = false;
    }

    async deleteUser() {
      let user_profile = this.user;
      let user_projects_list = [];
      let succeeded = true;

      if (this.user['role'] === 'PU' || this.user['role'] === 'PR') {
        const profile = await getUserProfile(this.project.company, this.user['id']);
        user_profile = {...user_profile, ...profile};
        user_projects_list = user_profile['projects'];
      }else {
        user_projects_list = this.projectsList.map((project) => project.id);
      }

      const restricted_to_remove = {}, mobile_roles_to_remove = {};
      const current_notifications = cloneDeep(this.notifications);

      user_projects_list.forEach((project_id) => {
        const project = this.projectsList.find((project) => project.id === project_id);
        if (project && project.restricted && project.restricted.includes(user_profile['email'])) {
          const new_restricted = project.restricted.filter((user_email) => user_email !== user_profile['email']);
          restricted_to_remove[project_id] = new_restricted;
        }

        const mobile_roles_project = this.mobile_roles[project_id];
        if (mobile_roles_project && mobile_roles_project.find((user) => user.email && user.email === user_profile['email'])) {
          const new_mobile_roles = mobile_roles_project.filter((user) => user.email && user.email !== user_profile['email']);
          mobile_roles_to_remove[project_id] = new_mobile_roles;
        }

        const notifications_project = current_notifications.projects.find((project) => project.project_id === project_id);
        const notifications_project_index = current_notifications.projects.findIndex((project) => project.project_id === project_id);
        if (notifications_project && notifications_project.users.find((user) => user.email && user.email.email_address === user_profile['email'])) {
          const new_users = notifications_project.users.filter((user) => user.email && user.email.email_address !== user_profile['email']);
          current_notifications.projects[notifications_project_index].users = new_users;
          if (!current_notifications.projects[notifications_project_index].users.length) {
            current_notifications.projects = current_notifications.projects.slice(0, notifications_project_index).concat(current_notifications.projects.slice(notifications_project_index + 1));
          }
        }
      });

      Object.entries(restricted_to_remove).forEach(async ([project_id, new_value]) => {
        const result = await setProjectProperty(this.project.company, project_id, 'dashboard.Forbidden_users', new_value);
        if (result) {
          vuex.commit('Projects/updateRestrictedByProject', {project_id, value: new_value});
        }else {
          succeeded = false;
        }
      });

      Object.entries(mobile_roles_to_remove).forEach(async ([project_id, new_value]) => {
        const result = await setProjectProperty(this.project.company, project_id, 'mobile.user_roles', {users: new_value});
        if (result) {
          this.mobile_roles[project_id] = new_value;
        }else {
          succeeded = false;
        }
      });

      const notifications_response = await setCompanyProperty(this.project.company, 'company.notification_settings', current_notifications);
      if (notifications_response) {
        this.notifications = current_notifications;
        vuex.commit('Props/updateCompanyProperty', {property_name: 'company.notification_settings', current_notifications});
      }
      succeeded = succeeded && notifications_response ? true : succeeded;

      const delete_response = await deleteUser(this.project.company, user_profile['email']);
      succeeded = succeeded && delete_response;
      if (succeeded) {
        vuex.dispatch('Global/throwNotify', {
          type: 'success',
          title: this.$t('Success'),
          text: this.$t(`User deleted successfully`)
        });
      }else {
        vuex.dispatch('Global/throwNotify', {
          type: 'error',
          title: this.$t('Error'),
          text: this.$t(`Some of the user's information is not deleted`)
        });
      }
      this.closeUserDialog();
    }

    updateMobileProperty(property){
      this.mobile_roles[property.project_id] = property.value;
    }

    updateNotificationsSettings(value) {
      this.notifications = value;
    }

    async fetchNotifications() {
      if (!this.company_properties) {
        await vuex.dispatch('Props/fetchCompanyProperties', this.projectsList[0].company);
      }

      let notifications_property = this.company_properties && this.company_properties.find((prop) => prop.name === 'company.notification_settings') || null;
      if (notifications_property && notifications_property.value) {
        this.notifications = notifications_property.value;
      }else {
        notifications_property = { version: '1.0', projects: [] };
        this.projectsList.forEach((project) => {
          notifications_property.projects.push({
            project_id: project.id,
            group_id: project.group_id,
            users: []
          });
        });
        
        const response = await API.post(Types.COMPANIES, `${this.project.company}/properties/?name=company.notification_settings`, notifications_property);

        if (response && response.value) {
          this.notifications = response.value;
          vuex.commit('Props/updateCompanyProperty', {property_name: 'company.notification_settings', value: response.value});
        }
      }
    }

    addUserToTable() {
      this.fetchNotifications();
    }
}
